import logo from '../assets/img/logo.png';
import '../style/Footer.css';
import React from 'react';
import {
	MDBFooter,
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBIcon,
} from 'mdb-react-ui-kit';

export const Footer = () => {
	return (
		<MDBFooter
			// bgColor="light"
			className="footer">
			<section className="">
				<MDBContainer className="text-center text-md-start mt-5">
					<MDBRow className="mt-3">
						<MDBCol
							md="3"
							lg="4"
							xl="3"
							className="mx-auto mb-4">
							<h6 className="text-uppercase fw-bold mb-4">
								<img
									src={logo}
									alt="Logo"
									width="10"
									height="30"
									className="d-inline-flex "
								/>{' '}
								Perfection Painters
							</h6>
							<p>
								We provide quality work at affordable prices, while focusing on
								our clients' goals and expectations
							</p>
							<div>
								<a
									href="https://m.facebook.com/people/Perfection-Painters-of-Toronto/100069073224064/"
									target="_blank"
									rel="noreferrer"
									className="me-4 text-reset">
									<MDBIcon
										fab
										icon="facebook-f"
									/>
								</a>

								<a
									href="https://maps.app.goo.gl/YmzZX1JgndfspmX59"
									target="_blank"
									rel="noreferrer"
									className="me-4 text-reset">
									<MDBIcon
										fab
										icon="google"
									/>
								</a>
								<a
									href="https://www.instagram.com/perfectpaintgta/?hl=en"
									target="_blank"
									rel="noreferrer"
									className="me-4 text-reset">
									<MDBIcon
										fab
										icon="instagram"
									/>
								</a>
								<a
									href="https://ca.linkedin.com/in/perfection-painters-gta-371013198"
									target="_blank"
									rel="noreferrer"
									className="me-4 text-reset">
									<MDBIcon
										fab
										icon="linkedin"
									/>
								</a>
							</div>
						</MDBCol>

						<MDBCol
							md="2"
							lg="2"
							xl="2"
							className="mx-auto mb-4 footer-navigation">
							<h6 className="text-uppercase fw-bold mb-4">Navigation</h6>
							<p>
								<a
									href="#home"
									className="text-reset">
									Home
								</a>
							</p>
							<p>
								<a
									href="#aboutus"
									className="text-reset">
									About Us
								</a>
							</p>
							<p>
								<a
									href="#gallery"
									className="text-reset">
									Gallery
								</a>
							</p>
							<p>
								<a
									href="#connect"
									className="text-reset">
									Contact
								</a>
							</p>
						</MDBCol>

						<MDBCol
							md="3"
							lg="2"
							xl="2"
							className="mx-auto mb-4 footer-testimonial">
							<h6 className="text-uppercase fw-bold mb-4">Testimonial</h6>
							<p>
								<a
									href="https://homestars.com/companies/2932923-perfection-painters-gta"
									target="_blank"
									rel="noreferrer"
									className="text-reset">
									HomeStar
								</a>
							</p>
							<p>
								<a
									href="https://www.bark.com/en/ca/company/perfection-painters-of-toronto/V3Rkb/"
									target="_blank"
									rel="noreferrer"
									className="text-reset">
									Bark
								</a>
							</p>
							<p>
								<a
									href="https://www.yelp.ca/biz/perfection-painters-of-toronto-toronto"
									target="_blank"
									rel="noreferrer"
									className="text-reset">
									Yelp
								</a>
							</p>
						</MDBCol>

						<MDBCol
							md="4"
							lg="3"
							xl="3"
							className="mx-auto mb-md-0 mb-4">
							<h6 className="text-uppercase fw-bold mb-4">
								Contact & Location
							</h6>
							<p>
								<MDBIcon
									icon="home"
									className="me-2"
								/>
								Niagara-on-the-lake, ON
							</p>
							<p>
								<MDBIcon
									icon="envelope"
									className="me-3"
								/>
								ppaintersto@gmail.com
							</p>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</section>

			<div className="text-center p-3">
				© 2023 Copyright. All Rights Reserved.{' '}
			</div>
		</MDBFooter>
	);
};
