import { Container, Row, Col } from 'react-bootstrap';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import '../style/Banner.css';

import { BrowserRouter as Router } from 'react-router-dom';

export const Banner = () => {
	return (
		<section
			className="banner"
			id="home">
			<Container>
				<Row className="aligh-items-center">
					<Col
						xs={12}
						md={8}
						xl={7}>
						<TrackVisibility>
							{({ isVisible }) => (
								<div
									className={
										isVisible ? 'animate__animated animate__fadeIn' : ''
									}>
									<h1> Perfection Painters</h1>
									<p>
										We provide the highest quality of painting at affordable
										prices, while focusing on our clients' goals and
										expectations
									</p>
									<Router>
										<a href="#connect">
											<button className="vvd">
												<span>
													Let’s Connect
													<ArrowRightCircle size={25} />
												</span>
											</button>
										</a>
									</Router>
								</div>
							)}
						</TrackVisibility>
					</Col>
				</Row>
			</Container>
		</section>
	);
};
