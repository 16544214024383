import { Container, Row, Col, Tab, Nav, Accordion } from 'react-bootstrap';
import { ProjectCard } from './ProjectCard';
import abtImg1 from '../assets/img/about1.jpg';
import abtImg2 from '../assets/img/about3.jpg';
import abtImg3 from '../assets/img/about6.jpg';
// import abtBg from '../assets/img/about-bg.jpg';
import contactImg from '../assets/img/banner5.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/js/src/modal';
import '../style/Projects.css';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const AboutUs = () => {
	const projects = [
		{
			title: 'INTERIOR',
			description:
				'Need to freshen up your home in just a day? Let us handle it!',
			imgUrl: abtImg1,
		},
		{
			title: 'EXTERIOR',
			description:
				"Want to bring back your home's curb appeal? Let us do a great job for you!",
			imgUrl: abtImg2,
		},
		{
			title: 'COMMERCIAL',
			description:
				'Does your workplace need a transformation without the hassle? Give us a call! ',
			imgUrl: abtImg3,
		},
	];

	return (
		<section
			className="aboutus"
			id="aboutus">
			<Container className="aboutus-container ">
				<Row className="align-items-center">
					<h2>About Us</h2>
				</Row>
				{/* <Col>
					{' '}
					<img
						src={contactImg}
						alt="Instagram Icon"
					/>
				</Col> */}
				<Col>
					<section className="aboutus-container-p">
						<p>
							<span className="aboutus-gradient">Perfection Painters</span> is a
							high-quality, award-winning painting contractor, serving
							homeowners in the Niagara region. We are equipped with the
							expertise to deliver precise, beautiful, and long-lasting paint
							jobs for your home. We use only{' '}
							<span className="aboutus-gradient">
								the highest quality primers and paints.
							</span>{' '}
							We protect your possessions while we’re in your home and leave
							every job site cleaned up and pristine when we’re done.
						</p>
						<p>
							Painting the interior of your home is one of the quickest and most
							affordable ways to update your property. A fresh coat of paint
							improves your home’s appearance and increases its value when it’s
							time to sell. Painting your home may sound like a simple DIY
							project, but if you’ve ever tried to paint the inside of your
							home, you know what a headache it can be. In fact, DIY painting is
							one of the top projects that homeowners regret trying themselves.
							When you’re ready to paint, hire a professional from{' '}
							<span className="aboutus-gradient">Perfection Painters </span>to
							get the best results.
						</p>
						<p>
							We offer virtual quotes via phone, videos, and/or floor plans. We
							provide{' '}
							<span className="aboutus-gradient">
								fixed and predictable pricing.
							</span>{' '}
							Your quote will be detailed, itemized, transparent and
							customizable to your specific needs and requirements. We do not
							profit on paint purchasing for your project as we only charge you
							the amount that our suppliers charge us.
						</p>
					</section>
					<section>
						<p>
							We are highly rated and recommended by customers on{' '}
							<span className="aboutus-gradient">
								<a
									href="https://homestars.com/companies/2932923-perfection-painters-gta"
									target="_blank"
									rel="noreferrer">
									HomeStars
								</a>
								,{' '}
								<a
									href="https://maps.app.goo.gl/6TKPpGdNFoTwwxZa8?g_st=ic"
									target="_blank"
									rel="noreferrer">
									Google
								</a>
								, and{' '}
								<a
									href="https://www.bark.com/en/ca/company/perfection-painters-of-toronto/V3Rkb/"
									target="_blank"
									rel="noreferrer">
									Bark.com.
								</a>
							</span>
						</p>
					</section>
				</Col>
			</Container>
		</section>
	);
};
