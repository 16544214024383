import { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import logo from '../assets/img/logo.png';
import { HashLink } from 'react-router-hash-link';
import { BrowserRouter as Router } from 'react-router-dom';
import '../style/NavBar.css';

export const NavBar = () => {
	const [activeLink, setActiveLink] = useState('home');
	const [scrolled, setScrolled] = useState(false);

	useEffect(() => {
		const onScroll = () => {
			if (window.scrollY > 50) {
				setScrolled(true);
			} else {
				setScrolled(false);
			}
		};

		window.addEventListener('scroll', onScroll);

		return () => window.removeEventListener('scroll', onScroll);
	}, []);

	const onUpdateActiveLink = (value) => {
		setActiveLink(value);
	};

	return (
		<Router>
			<Navbar
				expand="md"
				className={scrolled ? 'scrolled' : ''}>
				<Container className="container shadow-none">
					<Navbar.Brand
						href="#home"
						className="navbar-text-title">
						<img
							src={logo}
							alt="Logo"
							width="10"
							height="30"
							className="d-inline-flex "
						/>{' '}
						<a
							class="navbar-brand"
							href="#home">
							Perfection Painters
						</a>
					</Navbar.Brand>

					<Navbar.Toggle
						class="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</Navbar.Toggle>
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="ms-auto">
							<Nav.Link
								href="#home"
								className={
									activeLink === 'home' ? 'active navbar-link' : 'navbar-link'
								}
								onClick={() => onUpdateActiveLink('home')}>
								Home
							</Nav.Link>
							<Nav.Link
								href="#aboutus"
								className={
									activeLink === 'aboutus'
										? 'active navbar-link'
										: 'navbar-link'
								}
								onClick={() => onUpdateActiveLink('aboutus')}>
								About Us
							</Nav.Link>
							<Nav.Link
								href="#gallery"
								className={
									activeLink === 'gallery'
										? 'active navbar-link'
										: 'navbar-link'
								}
								onClick={() => onUpdateActiveLink('gallery')}>
								Gallery
							</Nav.Link>
						</Nav>

						<span className="navbar-text">
							<HashLink to="#connect">
								<button className="vvd">
									<span>Quote Project</span>
								</button>
							</HashLink>
						</span>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</Router>
	);
};
