import { useState, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import contactImg from '../assets/img/gal-6.jpg';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import '../style/Contacts.css';
import emailjs from '@emailjs/browser';
import { phoneNumberAutoFormat } from '../utils/phoneValidation';

// import dotenv from 'dotenv';
// dotenv.config();

// require('dotenv').config();
// console.log(process.env);
export const Contact = () => {
	const formRef = useRef();
	const [form, setForm] = useState({
		name: '',
		email: '',
		phoneNum: '',
		message: '',
		zipcode: '',
	});

	const [loading, setLoading] = useState(false);

	const handleChange = (e) => {
		const { target } = e;
		const { name, value } = target;

		setForm({
			...form,
			[name]: value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);

		emailjs
			.send(
				process.env.REACT_APP_SERVICE_ID, //service
				process.env.REACT_APP_TEMPLATE_ID, //template
				{
					from_name: form.name,
					to_name: 'Peter',
					from_email: form.email,
					to_email: 'ppaintersto@gmail.com',
					message: form.message,
					from_phoneNum: phoneNum,
					from_zipcode: form.zipcode,
				},
				process.env.REACT_APP_PUBLIC_ID //public
			)
			.then(
				() => {
					setLoading(false);
					alert('Thank you. We will get back to you as soon as possible.');

					setForm({
						name: '',
						email: '',
						phoneNum: '',
						message: '',
						zipcode: '',
					});
				},
				(error) => {
					setLoading(false);
					console.error(error);

					alert('Ahh, something went wrong. Please try again.');
				}
			);
	};
	//Phone number validation
	const [phoneNum, setPhoneNum] = useState('');
	const onChange = (e) => {
		const targetValue = phoneNumberAutoFormat(e.target.value);
		setPhoneNum(targetValue);
	};

	return (
		<section
			className="contact"
			id="connect">
			<Container>
				<Row className="align-items-center">
					<Col>
						<img
							src={contactImg}
							alt="Instagram Icon"
						/>
					</Col>
					<Col
						size={12}
						md={6}>
						<TrackVisibility>
							{({ isVisible }) => (
								<div
									className={
										isVisible ? 'animate__animated animate__fadeIn' : ''
									}>
									<h2>Contact Us for Quote</h2>

									<form
										ref={formRef}
										onSubmit={handleSubmit}
										className="mt-12 flex flex-col gap-8">
										<Row>
											<input
												type="text"
												name="name"
												value={form.name}
												onChange={handleChange}
												placeholder="What's your name?"
												className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
											/>{' '}
											<input
												type="email"
												name="email"
												value={form.email}
												onChange={handleChange}
												placeholder="What's your email address?"
												className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
											/>{' '}
											<input
												type="phoneNum"
												name="phoneNum"
												value={phoneNum}
												onChange={(handleChange, onChange)}
												maxLength={13}
												placeholder="What's your phone number?"
												className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
											/>{' '}
											<input
												type="zipcode"
												name="zipcode"
												value={form.zipcode}
												onChange={handleChange}
												maxLength={6}
												placeholder="What's your postal code?"
												className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
											/>{' '}
											<textarea
												rows={7}
												name="message"
												value={form.message}
												onChange={handleChange}
												placeholder="What's your project?"
												className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
											/>{' '}
											<p className="">
												<small>
													*Please email to us at ppaintersto@gmail.com if you
													have floorplan/photographs of the project.
												</small>
											</p>
											<button type="submit">
												{loading ? 'Sending...' : 'Send'}
											</button>
										</Row>
									</form>
								</div>
							)}
						</TrackVisibility>
					</Col>
				</Row>
			</Container>
		</section>
	);
};
