import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../style/Portfolio.css';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import gal1 from '../assets/img/gal-1.jpg';
import gal2 from '../assets/img/gal-2.jpg';
import gal3 from '../assets/img/gal-3.jpg';
import gal4 from '../assets/img/gal-4.jpg';
import gal5 from '../assets/img/gal-5.jpg';
import gal6 from '../assets/img/gal-6.jpg';
import gal7 from '../assets/img/gal-7.jpg';
import gal8 from '../assets/img/gal-8.jpg';
import gal9 from '../assets/img/gal-9.jpg';
import gal10 from '../assets/img/gal-10.jpg';
import gal11 from '../assets/img/gal-11.jpg';
import gal12 from '../assets/img/banner-1.jpg';
import gal13 from '../assets/img/banner-2.jpg';
import gal14 from '../assets/img/gal-12.jpg';
import gal15 from '../assets/img/gal-13.jpg';
import gal16 from '../assets/img/gal-14.jpg';
import gal17 from '../assets/img/gal-15.jpg';
// import gal14 from '../assets/img/banner1.jpg';

export const Gallery = () => {
	const responsive = {
		desktop: {
			breakpoint: {
				max: 3000,
				min: 1024,
			},
			items: 3,
			partialVisibilityGutter: 30,
		},
		mobile: {
			breakpoint: {
				max: 464,
				min: 0,
			},
			items: 1,
			partialVisibilityGutter: 30,
		},
		tablet: {
			breakpoint: {
				max: 1024,
				min: 464,
			},
			items: 2,
			partialVisibilityGutter: 30,
		},
	};

	const portPic = [
		{
			imgUrl: gal1,
			title: 'image1',
		},
		{
			imgUrl: gal2,
			title: 'image1',
		},
		{
			imgUrl: gal3,
			title: 'image1',
		},
		{
			imgUrl: gal4,
			title: 'image1',
		},
		{
			imgUrl: gal5,
			title: 'image1',
		},
		{
			imgUrl: gal6,
			title: 'image1',
		},
		{
			imgUrl: gal7,
			title: 'image1',
		},
		{
			imgUrl: gal8,
			title: 'image1',
		},
		{
			imgUrl: gal9,
			title: 'image1',
		},
		{
			imgUrl: gal10,
			title: 'image1',
		},
		{
			imgUrl: gal11,
			title: 'image1',
		},
		{
			imgUrl: gal12,
			title: 'image1',
		},
		{
			imgUrl: gal13,
			title: 'image1',
		},
		{
			imgUrl: gal14,
			title: 'image1',
		},
		{
			imgUrl: gal15,
			title: 'image1',
		},
		{
			imgUrl: gal16,
			title: 'image1',
		},
		{
			imgUrl: gal17,
			title: 'image1',
		},
	];
	//show modal
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	//setting modal data
	const [modalData, setModalData] = useState();
	const isImageAvailable = (data) => {
		return data && data.imgUrl && data.imgUrl.trim().length > 0;
	};
	return (
		<section
			className="portfolio"
			id="gallery">
			<div className="portfolio-bx ">
				<h2 className="portfolio-gradient">Gallery</h2>
				<Carousel
					className="portfolio-slider"
					responsive={responsive}
					infinite={true}
					swipeable={true}
					arrows
					keyBoardControl
					rewind={false}
					rewindWithAnimation={false}
					swipeable
					additionalTransfrom={0}
					autoPlaySpeed={3000}
					centerMode={false}
					draggable
					keyBoardControl
					minimumTouchDrag={80}
					pauseOnHover
					renderArrowsWhenDisabled={false}
					renderButtonGroupOutside={false}
					renderDotsOutside={false}
					showDots>
					{portPic.map((g) => {
						return (
							<div>
								<img
									className="slider-img"
									src={g.imgUrl}
									onClick={() => {
										setModalData(g);
										setShow(true);
									}}
									alt="Perfection Painters gallery of pictures"
								/>
							</div>
						);
					})}
				</Carousel>

				<Modal
					show={show}
					onHide={handleClose}
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered>
					<Modal.Header closeButton></Modal.Header>
					<Modal.Body>
						{isImageAvailable(modalData) ? (
							<img
								src={modalData.imgUrl}
								alt="Perfection Painters gallery of pictures"
								className="w-100 h-100"
								onClick={handleShow}
							/>
						) : (
							<p>No Image Available</p>
						)}
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="secondary"
							onClick={handleClose}>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
				<span className="footnote">
					All photos used on this website are examples of actual work performed
					by Perfection Painters
				</span>
			</div>
		</section>
	);
};
